<template>
  <div class="router-wrap">
    <div class="router-banner"></div>
    <div class="newsDetails-content">
      <ul class="tab-box">
        <Back
          :name="langTrue == 'en-us' ? 'Join us' : '加入我们'"
          backtop="25px"
          backright="40px"
          homeBack="/HomePage"
          :homeName="langTrue == 'en-us' ? 'home page' : '首页'"
        />

        <li
          class="tab-item"
          v-for="(val, ind) in tabList"
          :class="{ active: activeTab == val.id }"
          @click="activeTabclick(val.id)"
          :key="ind"
        >
          {{ langTrue == 'en-us' ? val.nameEn : val.name }}
        </li>
      </ul>
      <div>
        <div class="job-banner">
          <img src="@/assets/imgs/joinUs/joinUs-banner-2.jpg" alt="" />
        </div>
        <ul class="job-list"  v-loading="loading">
          <li
            v-for="(val, ind) in jobsList"
            @click="showDetailsclick(val.id)"
            :key="ind"
          >
            <p>{{ val.job }}</p>
            <div>
              <p>
                {{ langTrue == "en-us" ? "hands-on background：" : "工作经验："
                }}{{ val.experience }} /
                {{ langTrue == "en-us" ? "education：" : "学历："
                }}{{ val.education }} /
                {{ langTrue == "en-us" ? "Number of recruits：" : "招聘人数："
                }}{{ val.nums }}
              </p>
              <span>{{ val.address }}</span>
            </div>
          </li>
        </ul>
        <div class="pagination" v-if="joblistlength !== 0">
          <div class="pagination-first" @click="handleCurrentChange(1)">
            {{ langTrue == "en-us" ? "Home page" : "首页" }}
          </div>
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="5"
            :prev-text="langTrue == 'en-us' ? 'previous page' : '上一页'"
            :next-text="langTrue == 'en-us' ? 'next page' : '下一页'"
            layout=" prev, pager, next"
            :total="joblistlength"
          >
          </el-pagination>
          <div class="pagination-last" @click="handleCurrentChange(total)">
            {{ langTrue == "en-us" ? "Last page" : "尾页" }}
          </div>
        </div>
        <div v-if="nodata">
          <NoData></NoData>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Back from "@/components/back";
import { selectWantInfoList, wantInfoDetail } from "@/api/common.js";
import NoData from "@/components/noData.vue";
export default {
  name: "JoinUsDetails",
  data() {
    return {
      langTrue: sessionStorage.getItem("langType"),
      jovlistId: "",
      activeTab: sessionStorage.getItem('joinusId')||1,
      tabList: [
        {
          name: "社会招聘",
          nameEn: "Social recruitment",
          id: 1,
        },
        {
          name: "校园招聘",
          nameEn: "Campus Recruitment",
          id: 2,
        },
      ],
      joblistlength: 0,
      jobsList: [
        // {
        //   experience: "3-5年",
        //   job: "UI设计师",
        //   education: "本科",
        //   nums: "若干人",
        //   address: "上海市 - 浦东新区",
        // },
      ],
      currentPage: 1,
      total:1,
      recruitmentdata: {
        job: "",
        address: "",
        experience: "",
        education: "",
        nums: "",
        time: "",
        description: "",
        requirements: "",
        delivery: "",
      },
      nodata:false,
      loading:false,
    };
  },
  components: {
    Footer,
    Back,
    NoData,
  },
  computed: {
    costRequest() {
      return {
        wantType: this.activeTab,
        id: this.jovlistId,
      };
    },
  },

  mounted() {
    this.getselectPartnerList();
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getselectPartnerList()
    },
    activeTabclick: function (val) {
      this.currentPage = 1;
      this.activeTab = val;
      sessionStorage.setItem('joinusId',val);
      this.getselectPartnerList();

    },
    showDetailsclick: function (val) {
      this.jovlistId = val;
      sessionStorage.setItem("JoinUsItemId",val)
      sessionStorage.setItem("JoinUsItemName",this.tabList[this.activeTab - 1].name)
      sessionStorage.setItem("JoinUsItemNameEn",this.tabList[this.activeTab - 1].nameEn)
      this.$router.push({
        name:"JoinUsItemDetails",
        // params:{
        //   id:val,
        //   name: this.tabList[this.activeTab - 1].name
        // }
      })

    },

    getselectPartnerList() {
      this.joblistlength = 0;
      this.jobsList = [];
      this.nodata = false;
      this.loading = true;

      selectWantInfoList({
        lang: sessionStorage.getItem("langType"),
        wantType: this.activeTab,
        pageSize: 5,
        pageNum: this.currentPage,
      }).then((res) => {
        let data = [];
        this.loading = false;
        this.total = Math.ceil(res.total / 5);
        res.rows.map((v, i) => {
          data.push({
            experience: v.experience,
            job: v.postName,
            education: v.education,
            nums: v.wantNum,
            address: v.workingPlace,
            id: v.id,
          });
        });
        this.joblistlength = res.total;
        this.jobsList = data;
        if(!this.jobsList.length){
          this.nodata = true;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.router-banner {
  display: flex;
  height: 662px;
  background: url("../assets/imgs/joinUs/joinUs-banner.jpg") center center;
}
.newsDetails-content {
  width: 1200px;
  min-height: 741px;
  margin: 0 auto;
  padding-top: 30px;
  position: relative;
  .tab-box {
    display: flex;
    margin-bottom: 40px;
    .tab-item {
      cursor: pointer;
      color: #444;
      font-size: 16px;
      height: 50px;
      line-height: 50px;
      margin-right: 74px;
      border-bottom: 2px solid transparent;
    }
    .tab-item::after {
      content: "";
      display: block;
      transition: all 200ms ease-in-out 0s;
      cursor: pointer;
      height: 2px;
      background: #0072cb;
      margin: 0 auto;
      width: 0;
    }
    .tab-item:hover,
    .tab-item.active {
      color: #0072cb;
    }
    .tab-item:hover::after,
    .tab-item.active::after {
      width: 100%;
    }
  }
  .job-banner {
    height: 286px;
    display: flex;
    margin-bottom: 30px;
  }
  .job-list {
    min-height: 300px;
    li {
      margin-bottom: 30px;
      background: #f6f6f6;
      padding: 27px 80px 27px 66px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      line-height: 1;
      height: 115px;
      cursor: pointer;
      & > p {
        font-size: 18px;
        color: #060606;
      }
      & > div {
        font-size: 16px;
        color: #444;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 85px;
    .pagination-first,
    .pagination-last {
      width: 82px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #eee;
      cursor: pointer;
    }
  }

  .details-content {
    .details-top {
      border-bottom: 1px solid #b3b3b3;
      padding: 0 40px 18px;
      margin-bottom: 20px;
      & > p {
        font-size: 20px;
        color: #444;
        margin-bottom: 25px;
      }
      & > div {
        font-size: 18px;
        display: flex;
        justify-content: space-between;

        & > span {
          color: #b3b3b3;
        }
      }
    }
    .details-bottom {
      padding: 0 40px 18px;
      font-size: 14px;
      color: #666;
      line-height: 30px;
      .details-title {
        font-size: 16px;
        color: #444;
      }
      .details-title-bottom {
        font-size: 16px;
        color: #666;
      }
      & > div {
        margin-bottom: 24px;
      }
    }
  }
}
</style>
